exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instructors-index-tsx": () => import("./../../../src/pages/instructors/index.tsx" /* webpackChunkName: "component---src-pages-instructors-index-tsx" */),
  "component---src-pages-members-portal-index-tsx": () => import("./../../../src/pages/members-portal/index.tsx" /* webpackChunkName: "component---src-pages-members-portal-index-tsx" */),
  "component---src-pages-news-and-updates-index-tsx": () => import("./../../../src/pages/news-and-updates/index.tsx" /* webpackChunkName: "component---src-pages-news-and-updates-index-tsx" */),
  "component---src-pages-our-dojang-index-tsx": () => import("./../../../src/pages/our-dojang/index.tsx" /* webpackChunkName: "component---src-pages-our-dojang-index-tsx" */),
  "component---src-pages-programs-index-tsx": () => import("./../../../src/pages/programs/index.tsx" /* webpackChunkName: "component---src-pages-programs-index-tsx" */),
  "component---src-pages-schedule-index-tsx": () => import("./../../../src/pages/schedule/index.tsx" /* webpackChunkName: "component---src-pages-schedule-index-tsx" */),
  "component---src-templates-blog-information-template-tsx": () => import("./../../../src/templates/blog-information-template.tsx" /* webpackChunkName: "component---src-templates-blog-information-template-tsx" */),
  "component---src-templates-dojang-information-template-tsx": () => import("./../../../src/templates/dojang-information-template.tsx" /* webpackChunkName: "component---src-templates-dojang-information-template-tsx" */),
  "component---src-templates-instructor-information-template-tsx": () => import("./../../../src/templates/instructor-information-template.tsx" /* webpackChunkName: "component---src-templates-instructor-information-template-tsx" */),
  "component---src-templates-program-information-template-tsx": () => import("./../../../src/templates/program-information-template.tsx" /* webpackChunkName: "component---src-templates-program-information-template-tsx" */)
}

